/* eslint-disable @next/next/no-img-element */
import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import MuiContainer from "@mui/material/Container";
import { TextField, Button, Snackbar, Alert } from "@mui/material";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import Link from "next/link";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import InfoModal from "./InfoModal";

const Container = styled.div`
  display: "flex";
  background-color: #000;
`;

const ContentContainer = styled(MuiContainer)`
  color: #fff;
  padding-top: 100px;
  padding-bottom: 64px;
`;

interface IFormValues {
  name: string;
  phone: string;
  email: string;
  message: string;
}

const ContactFormItem = styled.div`
  padding-top: 12pt;
  /* max-width: 400px; */
`;

const RightSideContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContactInfoWithIconContainer = styled.div`
  display: flex;
`;

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const SomeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 50px;
`;

const ContactInfo = styled.div`
  margin-bottom: 10px;
`;

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Nimi on pakollinen tieto"),
  phone: Yup.string().required("Puhelinnumero on pakollinen tieto"),
  email: Yup.string()
    .email("Sähköpostiosoite on virheellinen")
    .required("Sähköposti on pakollinen tieto"),
  message: Yup.string().required("Viesti on pakollinen tieto"),
});

/**
 * Footer
 * @returns {JSX.Element}
 */
const Footer = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });
  const [successSnackbarOpen, setSuccesSnackbarOpen] = useState<boolean>(false);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const onFormSubmit = (values: any) => {
    fetch("https://webapi.safetycon.fi/api/v1/contact", {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    })
      .then((result) => {
        setSuccesSnackbarOpen(true);
      })
      .catch((error) => {
        setErrorSnackbarOpen(true);
      });
  };

  return (
    <Container id="footer">
      <ContentContainer>
        <Grid container>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ paddingTop: "30px", marginBottom: "100px" }}
          >
            <Typography variant="h2" component="div" gutterBottom>
              Ota yhteyttä
            </Typography>
            <Typography
              variant="subtitle1"
              component="div"
              gutterBottom
              style={{ maxWidth: "320px" }}
            >
              Jätä yhteystietosi ja viesti meille, niin me otamme Teihin
              yhteyttä.
            </Typography>

            <Grid container>
              <Grid item xs={12} md={8}>
                <form onSubmit={handleSubmit(onFormSubmit)}>
                  <ContactFormItem>
                    <label
                      htmlFor={"name"}
                      style={{ fontFamily: "Montserrat", fontSize: "14px" }}
                    >
                      Nimi*
                    </label>
                    <TextField
                      id="name"
                      {...register("name")}
                      error={errors.name ? true : false}
                      fullWidth
                      size={"small"}
                      sx={{ backgroundColor: "#fff", my: 1 }}
                    />
                    {errors.name && (
                      <Typography
                        sx={{
                          fontFamily: "Montserrat",
                          color: "#F00",
                          fontSize: "8pt",
                        }}
                      >
                        {errors.name.message}
                      </Typography>
                    )}
                  </ContactFormItem>

                  <ContactFormItem>
                    <label
                      htmlFor={"phone"}
                      style={{ fontFamily: "Montserrat", fontSize: "14px" }}
                    >
                      Puhelin*
                    </label>
                    <TextField
                      id="phone"
                      {...register("phone")}
                      error={errors.phone ? true : false}
                      fullWidth
                      size={"small"}
                      sx={{ backgroundColor: "#fff", my: 1 }}
                    />
                    {errors.phone && (
                      <Typography
                        sx={{
                          fontFamily: "Montserrat",
                          color: "#F00",
                          fontSize: "8pt",
                        }}
                      >
                        {errors.phone.message}
                      </Typography>
                    )}
                  </ContactFormItem>

                  <ContactFormItem>
                    <label
                      htmlFor={"email"}
                      style={{ fontFamily: "Montserrat", fontSize: "14px" }}
                    >
                      Sähköposti*
                    </label>
                    <TextField
                      id="email"
                      {...register("email")}
                      error={errors.email ? true : false}
                      fullWidth
                      size={"small"}
                      sx={{ backgroundColor: "#fff", my: 1 }}
                    />
                    {errors.email && (
                      <Typography
                        sx={{
                          fontFamily: "Montserrat",
                          color: "#F00",
                          fontSize: "8pt",
                        }}
                      >
                        {errors.email.message}
                      </Typography>
                    )}
                  </ContactFormItem>

                  <ContactFormItem>
                    <label
                      htmlFor={"message"}
                      style={{ fontFamily: "Montserrat", fontSize: "14px" }}
                    >
                      Viesti*
                    </label>
                    <TextField
                      id="message"
                      {...register("message")}
                      error={errors.message ? true : false}
                      fullWidth
                      sx={{ backgroundColor: "#fff", my: 1 }}
                      multiline
                      rows={8}
                    />
                    {errors.message && (
                      <Typography
                        sx={{
                          fontFamily: "Montserrat",
                          color: "#F00",
                          fontSize: "8pt",
                        }}
                      >
                        {errors.message.message}
                      </Typography>
                    )}
                  </ContactFormItem>
                  <Button
                    type="submit"
                    variant={"contained"}
                    color="secondary"
                    sx={{
                      borderRadius: "30px",
                      width: "200px",
                      padding: "8pt",
                      mt: "20pt",
                      fontFamily: "Montserrat",
                      fontSize: "12pt",
                    }}
                  >
                    Lähetä
                  </Button>
                </form>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <RightSideContainer>
              <InnerContainer id="innerContainer">
                <img
                  src={require("../../assets/images/Logo-valk.png?resize&size=250&format=webp")}
                  alt="Safetycon logo"
                  width={250}
                  height={90}
                  style={{ marginBottom: "180px" }}
                />

                <ContactInfoWithIconContainer>
                  <img
                    src={require("../../assets/images/Ikonit/Ikoneita-Phone.png?resize&size=22&format=webp")}
                    alt="Phone"
                    width={22}
                    height={22}
                    style={{ marginRight: "15px", marginTop: "5px" }}
                    //height={50}
                  />
                  <ContactContainer>
                    <ContactInfo>
                      <Typography
                        fontWeight="bold"
                        component="div"
                        variant="subtitle1"
                      >
                        Teemu Matilainen
                      </Typography>
                      <Typography component="div" variant="subtitle1">
                        +358 (0)44 583 6272
                      </Typography>
                    </ContactInfo>
                    <ContactInfo>
                      <Typography
                        component="div"
                        variant="subtitle1"
                        fontWeight="bold"
                      >
                        Iivari Valkonen
                      </Typography>
                      <Typography component="div" variant="subtitle1">
                        +358 (0)50 376 9684
                      </Typography>
                    </ContactInfo>
                  </ContactContainer>
                </ContactInfoWithIconContainer>

                <ContactInfoWithIconContainer>
                  <img
                    src={require("../../assets/images/Ikonit/Ikoneita-Mail.png?resize&size=30&format=webp")}
                    alt="Mail"
                    width={22}
                    height={22}
                    style={{ marginRight: "15px", marginTop: "5px" }}
                  />
                  <ContactContainer>
                    <Typography component="div" variant="subtitle1">
                      teemu.matilainen@safetycon.fi
                    </Typography>
                    <Typography component="div" variant="subtitle1">
                      iivari.valkonen@safetycon.fi
                    </Typography>
                  </ContactContainer>
                </ContactInfoWithIconContainer>

                <SomeContainer>
                  <Link
                    href={"https://www.linkedin.com/company/safetycon-oy/"}
                    passHref
                  >
                    <LinkedInIcon
                      sx={{
                        color: "#ed4048",
                        width: "50px",
                        height: "auto",
                        mr: "20px",
                        "&:hover": { cursor: "pointer" },
                      }}
                    />
                  </Link>
                  <Link
                    href={"https://www.instagram.com/safetycon_oy/"}
                    passHref
                  >
                    <InstagramIcon
                      sx={{
                        color: "#ed4048",
                        width: "50px",
                        height: "auto",
                        mr: "20px",
                        "&:hover": { cursor: "pointer" },
                      }}
                    />
                  </Link>
                  <Link
                    href={
                      "https://www.facebook.com/profile.php?id=100075063323097"
                    }
                    passHref
                  >
                    <FacebookIcon
                      sx={{
                        color: "#ed4048",
                        width: "50px",
                        height: "auto",
                        "&:hover": { cursor: "pointer" },
                      }}
                    />
                  </Link>
                </SomeContainer>
                <Button color={'secondary'}  variant={'text'} onClick={() => setModalOpen(true)} sx={{marginTop:'16px'}}>Tietosuojaseloste</Button>
              </InnerContainer>
            </RightSideContainer>
          </Grid>
        </Grid>
      </ContentContainer>
      <InfoModal handleClose={() => setModalOpen(false)} isOpen={modalOpen} />
      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSuccesSnackbarOpen(false)}
      >
        <Alert severity="success" onClose={() => setSuccesSnackbarOpen(false)}>
          Kiitos yhteydenotostasi!
        </Alert>
      </Snackbar>

      <Snackbar
        open={errorSnackbarOpen}
        autoHideDuration={6000}
        onClose={() => setErrorSnackbarOpen(false)}
      >
        <Alert severity="error" onClose={() => setErrorSnackbarOpen(false)}>
          Virhe lähettäessä palautetta!
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Footer;
