import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material'
import React from 'react'

interface Props {
  isOpen : boolean;
  handleClose : () => void;
}

const InfoModal = ({isOpen, handleClose} : Props) => {

  return (
    <Dialog 
      open={isOpen}
      onClose={handleClose}
      maxWidth={'lg'}
    >

      <DialogTitle>
        Tietosuojaseloste
      </DialogTitle>
      <DialogContent>
        <DialogContentText component="div">
<Typography variant="subtitle1" sx={{fontStyle: 'italic', fontWeight: 'bold'}}>EU:n yleisen tietosuoja-asetuksen 2016/679 13 artiklan mukainen seloste</Typography>
<Typography variant="body2">Päivitetty 19.1.2021</Typography>
<Typography variant="h6">1. Rekisteri</Typography>
<Typography variant="body2">Safetycon.fi-verkkopalvelun yhteydenottolomake</Typography>
<Typography variant="h6">2. Rekisterinpitäjä</Typography>
<Typography variant="body2">Safetycon Oy, Selännekatu 15, 50100 Mikkeli, 0445836272</Typography>
<Typography variant="h6">3. Yhteyshenkilö rekisteriä koskevissa asioissa</Typography>
<Typography variant="body2">Teemu Matilainen, Safetycon Oy 0445836272, teemu.matilainen@safetycon.fi</Typography>
<Typography variant="h6">4. Tietosuojavastaavan yhteystiedot</Typography>
<Typography variant="body2">Teemu Matilainen, Safetycon Oy 0445836272, teemu.matilainen@safetycon.fi</Typography>

<Typography variant="h6">5. Henkilötietojen käsittelyn tarkoitus ja käsittelyn oikeusperuste</Typography>
<Typography variant="body2">Yhteydenottolomakkeiden tarkoitus on tarjota mahdollisuus ottaa yhteyttä ja
Safetycon Oy yritykseen ilmaista palvelukonsultaatiota varten. Lomakkeella
kysytään lähettäjän yhteystiedot yhteydenottopyyntöä varten. Kysyttäviä tietoja ovat
nimi, puhelinnumero ja sähköpostiosoite. Henkilötietojen käsittelyn perusteina
ovat mahdollisuus vastata asiakkaan palveluntarpeeseen, jolla hän on yritystä
lähestynyt. Henkilötietoja käsitellään henkilötietolain sallimissa ja edellyttämissä
rajoissa.
</Typography>
<Typography variant="body2" sx={{fontStyle:'italic'}}>EU:n yleinen tietosuoja-asetus 6 artikla kohta e</Typography>
<Typography variant="h6">6. Rekisterin tietosisältö </Typography>
<Typography variant="body2">Lomakkeelta tallennetaan ne yhteystiedot (nimi, sähköpostiosoite, puhelinnumero),
jotka yhteydenottopyynnön lähettäjä on ilmoittanut sekä vapaavalintainen viesti.</Typography>
<Typography variant="h6">7. Säännönmukaiset tietolähteet </Typography>
<Typography variant="body2">Tiedot saadaan pääsääntöisesti yhteydenottopyynnön lähettäjiltä itseltään. Tietoja
tallennetaan vain Safetycon Oy:n safetycon.fi-verkkopalvelun
yhteydenottolomakkeiden lähettämisen yhteydessä. </Typography>

<Typography variant="h6">8. Tietojen säännönmukaiset luovutukset</Typography>
<Typography variant="body2">Tietoja ei luovuteta säännönmukaisesti organisaation ulkopuolelle. Yksittäinen
viesti luovutetaan vain niille yrityksen työntekijöille, joiden asiantuntemusta
palautteeseen vastaamiseen tarvitaan tai joille palaute on osoitettu. Tietoja
kerrotaan tai luovutetaan ulkopuolisille vain lakiin perustuvan ilmoitusvelvollisuuden
johdosta.</Typography>
<Typography variant="h6">9. Tietojen siirto EU:n tai EFTA:n ulkopuolelle</Typography>
<Typography variant="body2">Henkilötietoja ei siirretä EU:n tai EFTA:n ulkopuolelle.</Typography>
<Typography variant="h6">10. Tietojen säilytysaika</Typography>
<Typography variant="body2">Palautelomakkeilla tehdyissä yhteydenotoissa ilmoitettujen henkilötietojen säilytysaika on 5 vuotta.</Typography>
<Typography variant="h6">11. Rekisterin suojauksen periaatteet </Typography>
<ul>
  <li>
  <Typography variant="body2">Manuaalinen aineisto: Sähköisessä muodossa oleva palaute
tulostetaan vain tarvittaessa. Paperitulosteet tuhotaan välittömästi
käsittelyn jälkeen. Manuaalista aineistoa käsitellään lukituissa tiloissa,
joihin on rajattu pääsy.</Typography>
  </li>
  <li>
  <Typography variant="body2">Sähköinen aineisto: Saapuneiden yhteydenottopyyntöjen luku-,
lähetys- ja poistamisoikeudet on rajattu vain safetycon.fi-
verkkopalvelusta vastaaville työntekijöille. Rekisterinpitäjän
tietoverkko ja laitteisto, jolla rekisteri sijaitsee, on suojattu normaalisti
käytössä olevilla teknisillä suojausmenetelmillä. Rekisterin
käyttöoikeus edellyttää henkilökohtaista käyttäjätunnusta ja
salasanaa, jotka myönnetään ainoastaan safetycon.fi-
verkkopalvelusta vastaaville työntekijöille.</Typography>
    </li>
</ul>





<Typography variant="h6">12. Rekisteröidyn oikeudet</Typography>

<Typography variant="body2">Rekisteröidyllä on oikeus tarkastaa hänestä rekisteriin merkityt tiedot, vaatia
virheellisen tiedon oikaisua sekä muutoinkin turvautua laissa turvattuihin
oikeuksiinsa. Henkilötietojen käsittely perustuu rekisteröidyn antamaan
suostumukseen ja rekisteröidyllä on oikeus milloin tahansa perua antamansa
suostumus sekä pyytää kyseisten tietojen poistamista.
Henkilön, joka haluaa tarkastaa, korjata tai poistaa itseään koskevat tiedot, on
esitettävä tätä tarkoittava kirjallinen pyyntö rekisterinpitäjälle omakätisesti
allekirjoitetussa tai sitä vastaavalla tavalla varmennetussa asiakirjassa.
Rekisteröidyllä on oikeus tehdä valitus valvontaviranomaiselle
Tietosuojavaltuutetun toimistoon, jos hän katsoo, että hänen henkilötietojensa
käsittelyssä on toimittu EU:n yleisen tietosuoja-asetuksen vastaisesti.</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>Sulje</Button>
      </DialogActions>
    </Dialog>
  )
}

export default InfoModal
